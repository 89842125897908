import React, {FunctionComponent} from 'react'

const JadeBlogContactUs: FunctionComponent = (props) => {
    return (
        <div className="max-w-7xl h-auto justify-self-start">
            <div className="center py-8 pl-8">
                <h1 className="font-medium text-3xl mb-2">Contact information</h1>

                <h2 className="font-medium text-xl mb-2">Name</h2>
                <p className="my-2">
                    BarNetwork Pty Limited
                </p>
                <h2 className="font-medium text-xl mb-2">ABN</h2>
                <p className="my-2">
                    32 092 121 198
                </p>

                <h2 className="font-medium text-xl mb-2">Phone</h2>
                <p className="my-2">
                    <a className="text-heading-green" href="tel:+61288159079">+61 (2) 8815 9081</a>
                </p>
                <h2 className="font-medium text-xl mb-2">Address</h2>
                <address className="my-2">
                    BarNetwork Pty Limited<br/>Level 22<br/>52 Martin Place<br/>Sydney NSW 2000
                </address>
                <h2 className="font-medium text-xl mb-2">Who to contact</h2>
                {/*<p className="my-2">*/}
                {/*    For Sales and Subscriptions, please email: <br/> <a className="text-heading-green"*/}
                {/*                                                        href="mailto:sales@victorianreports.com.au">sales@victorianreports.com.au</a><br/>*/}
                {/*</p>*/}
                <p className="my-2">
                    For Editorial, Access and Content, please email <br/> <a className="text-heading-green"
                                                                             href="mailto:editors@jade.io">editors@jade.io</a>
                </p>

                <p className="my-2">
                    Reach out on social media:
                </p>

                <div className="flex">

                    <a href="https://www.linkedin.com/company/barnetjade/"
                       className="text-gray-400 hover:text-gray-500">
                        <span className="sr-only">LinkedIn</span>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" className="h-16 w-16 mr-6">
                            <path
                                d="M29.63.001H2.362C1.06.001 0 1.034 0 2.306V29.69C0 30.965 1.06 32 2.362 32h27.27C30.937 32 32 30.965 32 29.69V2.306C32 1.034 30.937.001 29.63.001z"
                                fill="#0E56A3"/>

                            <path
                                d="M4.745 11.997H9.5v15.27H4.745zm2.374-7.6c1.517 0 2.75 1.233 2.75 2.75S8.636 9.9 7.12 9.9a2.76 2.76 0 0 1-2.754-2.753 2.75 2.75 0 0 1 2.753-2.75m5.35 7.6h4.552v2.087h.063c.634-1.2 2.182-2.466 4.5-2.466 4.806 0 5.693 3.163 5.693 7.274v8.376h-4.743V19.84c0-1.77-.032-4.05-2.466-4.05-2.47 0-2.85 1.93-2.85 3.92v7.554h-4.742v-15.27z"
                                fill="#fff"/>
                        </svg>

                    </a>



                    <a href="https://twitter.com/barnetjade"
                       className="inline text-heading-green hover:text-gray-500 mr-6">
                        <span className="sr-only">Twitter</span>
                        <svg className="h-16 w-16" fill="#0E56A3" viewBox="0 0 24 24" aria-hidden="true">
                            <path
                                d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84"/>
                        </svg>
                    </a>

                </div>
            </div>
        </div>
    )
}

export default JadeBlogContactUs
