import React, {FunctionComponent}             from 'react'
import {PortableTextRenderer, ProjectColours} from '@open-law/open-law-shared'
import {BlogPostData}                         from '../../../types/vr-blog'


interface VrBlogPostListProps {
    data: { node: BlogPostData }[]
}

const OpenLawBlogPostList: FunctionComponent<VrBlogPostListProps> = (props) => {

    // console.log('VR Blog Post List Data: ', props.data)
    const {data} = props

    const renderCategoryBubble = (bubbleTitle: string, parentCategory: string) => {
        const titleAndParentDoNotMatch: boolean = (parentCategory !== 'featured') && (bubbleTitle !== 'featured')
        let isFeatured: boolean
        bubbleTitle === 'Featured' ? (isFeatured = true) : isFeatured = false
        let color: string
        bubbleTitle === 'Mentions' ? (color = 'blue-250') : color = 'green-case-notes'
        return !titleAndParentDoNotMatch && !isFeatured ? (
            <div className={`flex items-center justify-center bg-${color} rounded-sm h-5 w-20 mb-3`}>
                <h3 className="text-white text-center text-xs">
                    {bubbleTitle}
                </h3>
            </div>

        ) : null
    }

    const renderBlogPostListItem = (
        post: BlogPostData,
        colour: ProjectColours
    ) => post ? (
        <div className={'p-8 w-full flex flex-wrap justify-start gap-4'}>
            <a href={`/open-law/blog/posts/${post.slug.current}`} className={``}>
                <p>
                    {post.publishedAt}
                </p>
                <h2 className="font-medium text-2xl">
                    {post.title}<br/>
                    {
                        post.subTitle ? (
                            <span className="text-gray-600 text-lg">
                                                                        {post.subTitle}
                                                                            </span>
                        ) : null
                    }
                </h2>
                <p className="">
                    {
                        post.authors ? post.authors.map((author, idx) => (
                                <span>
                                    {author.name}{post.authors.length > 0 && post.authors.length !== idx + 1 ? ', ' : ''}
                                </span>
                            )
                        ) : null
                    }
                </p>
                <div className="max-w-xl">
                    {
                        post.excerpt ? (
                            <PortableTextRenderer projectColour={'dark-green'} blocks={post.excerpt}/>
                        ) : null
                    }
                </div>
            </a>
        </div>
    ) : null


    return (
        <div className="max-w-7xl h-auto justify-self-start">
            <header className="p-8">
                <h1 className="text-3xl font-bold pb-2">
                    All Posts
                </h1>
                <p>
                    The full listing of posts on the Open Law blog.
                </p>
            </header>

            {
                data ? data.map((post) => renderBlogPostListItem(post.node, 'green')) : null
            }
        </div>
    )
}

export default OpenLawBlogPostList
