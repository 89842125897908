import {
    graphql,
    PageProps
}                                   from 'gatsby'
import React, { FunctionComponent } from 'react'
import {
    BlogCategory,
    BlogPageContext,
    BlogPageData
}                                   from '../../../types/vr-blog'
import BlogLayout                   from '../blog-common/blog-layout'
import OpenLawBlogCategory          from './open-law-blog-category'
import OpenLawBlogContactUs         from './open-law-blog-contact-us'
import OpenLawBlogPost              from './open-law-blog-post'
import OpenLawBlogPostList          from './open-law-blog-post-list'
import OpenLawBlogsByCategory       from './open-law-blogs-by-category'

export const openLawBlogPageQuery = graphql`
    query OpenLawBlogPageQuery($categorySlug: String, $postId: String, $useFrontPageCategoryQuery: Boolean = false, $useSingleCategoryQuery: Boolean = false, $useAllPostsQuery: Boolean = false, $useSinglePostQuery: Boolean = false, $limit: Int, $skip: Int) {
        categoryWithPosts: allSanityPost(
            filter: {categories: {elemMatch: {slug: {current: {eq: $categorySlug}}}}, slug: {current: {ne: null}}, publishTo: {eq: "open-law-blog"}}
            sort: {fields: publishedAt, order: DESC}
        ) {
            edges @include(if: $useSingleCategoryQuery) {
                node {
                    slug {
                        current
                    }
                    publishedAt
                    publishTo
                    authors {
                        name
                    }
                    title
                    subTitle
                    categories {
                        title
                        slug {
                            current
                        }
                    }
                }
            }
        }
        categories: allSanityCategory {
            edges {
                node {
                    title
                    slug {
                        current
                    }
                    description
                    categoryColour
                }
            }
        }
        postsByCategory: allSanityPost(
            filter: {slug: {current: {ne: null}}, publishTo: {eq: "open-law-blog"}}
            sort: {fields: publishedAt, order: DESC}
        ) {
            group(field: categories___slug___current, limit: 7) @include(if: $useFrontPageCategoryQuery) {
                nodes {
                    categories {
                        title
                        slug {
                            current
                        }
                        categoryColour
                    }
                    slug {
                        current
                    }
                    publishedAt
                    publishTo
                    authors {
                        name
                    }
                    title
                    subTitle
                }
                fieldValue
            }
        }
        post: sanityPost(id: {eq: $postId}) @include(if: $useSinglePostQuery) {
            title
            subTitle
            publishedAt(formatString: "DD-MM-YYYY")
            mainImage {
                alt
                asset {
                    url
                }
            }
            categories {
                title
                slug {
                    current
                }
            }
            excerpt: _rawExcerpt(resolveReferences: {maxDepth: 10})
            authors {
                name
                slug {
                    current
                }
                title
                image {
                    alt
                    asset {
                        url
                    }
                }
                contactEmail
            }
            body: _rawBody(resolveReferences: {maxDepth: 10})
        }
        posts: allSanityPost(
            filter: {slug: {current: {ne: null}}, publishTo: {eq: "open-law-blog"}}
            sort: {fields: publishedAt, order: DESC}
            limit: $limit
            skip: $skip
        ) @include(if: $useAllPostsQuery) {
            edges {
                node {
                    title
                    subTitle
                    slug {
                        current
                    }
                    publishedAt(formatString: "DD-MM-YYYY")
                    mainImage {
                        alt
                        asset {
                            url
                        }
                    }
                    excerpt: _rawExcerpt(resolveReferences: {maxDepth: 10})
                    authors {
                        name
                        slug {
                            current
                        }
                        title
                        image {
                            alt
                            asset {
                                url
                            }
                        }
                        contactEmail
                    }
                }
            }
        }
    }
`

const OpenLawBlogPage: FunctionComponent<PageProps<BlogPageData, BlogPageContext>> = (props) => {

    const {data, pageContext, path} = props
    console.log('Open Law Blog Page', props)

    const theme                    = 'dark-blue|white|blue|white'
    const createCategoryMap: (
        categories: { edges: { node: BlogCategory }[] }
    ) => Map<string, BlogCategory> =
              (categories) => {
                  const cats: Map<string, BlogCategory> = new Map()
                  data.categories.edges.forEach(({node}) => node?.slug && node?.slug?.current ? cats.set(node.slug.current, node) : null)
                  return cats
              }

    const content = (pageContext: BlogPageContext) => {
        let el: JSX.Element | null = null
        switch (pageContext.type) {
            case 'contact-us': {
                el = <OpenLawBlogContactUs/>
                break
            }
            case 'all-blogs': {
                el = data.posts ? <OpenLawBlogPostList data={data.posts.edges}/> : null
                break
            }
            case 'blog-post': {
                el = data.post ? <OpenLawBlogPost data={data.post}/> : null
                break
            }
            case 'front-page': {
                const groups                          = data.postsByCategory?.group.sort(
                    (a, b) => a.fieldValue === 'featured' ? -1 : 1
                )
                const cats: Map<string, BlogCategory> = createCategoryMap(data.categories)

                el = data.postsByCategory ?
                     (
                         <>
                             <h1 className="font-medium text-4xl pl-7 pt-10 mb-2">
                                 The Open Law Blog
                             </h1>
                             <h2 className="text-sm pt-4 pl-7 pb-10">
                                 The latest news about Open Law and its projects
                             </h2>
                             <OpenLawBlogsByCategory data={groups} categories={cats} theme={theme}/>
                         </>

                     ) : null

                break
            }
            case 'category-page': {
                const cats: Map<string, BlogCategory> = createCategoryMap(data.categories)
                el                                    = data.categoryWithPosts ? (
                    <OpenLawBlogCategory
                        data={{categoryName: pageContext.categorySlug, edges: data.categoryWithPosts.edges}}
                        categories={cats}
                        currentPage={pageContext.currentPage}
                        numberOfPages={pageContext.numberOfPages}
                        skip={pageContext.skip}
                        limit={pageContext.limit}
                        categoryColor={pageContext.categoryColor}
                    />
                ) : null
                break
            }
            default:
                el = <p>Error loading OpenLaw blog.</p>
                break
        }
        return el
    }

    return (
        <BlogLayout theme={{theme: 'dark-blue|white|blue|white'}} path={path} pageType={props.pageContext.type}>
            {content(pageContext)}
        </BlogLayout>
    )
}

export default OpenLawBlogPage
