import { createComponentTheme }     from '@open-law/open-law-shared'
import React, { FunctionComponent } from 'react'
import OpenLawLogo                  from '../../assets/logos/open-law-logo-light.svg'


interface BlogSideMenuProps {
    path: string
    theme: { theme: string }
}

const BlogSideMenu: FunctionComponent<BlogSideMenuProps> = (props) => {

    const {path, theme} = props

    const {
              headingColour,
              backgroundColour,
              highlightColour,
              textColour,
              hover,
              primaryButtonColour,
              primaryButtonText,
              outline
          } = createComponentTheme(theme)

    return (
        <nav className="hidden lg:flex max-w-sm">
            <div
                className={`${backgroundColour} pt-16 p-4 flex flex-col justify-between h-screen sticky top-0`}>
                <header className="p-4 w-80">
                    <h1 className="sr-only">
                        Open Law
                    </h1>
                    <div className="flex justify-center pt-7">
                        <img alt={'OpenLaw Logo'} className="w-52 " src={OpenLawLogo}/>
                    </div>

                    <p className="mt-10 text-white font-medium text-center tracking-wider">
                        The latest news regarding<br/> OpenLaw initiatives<br/>
                    </p>
                    <nav
                        className="flex flex-col gap-4 text-white text-lg font-normal text-left content-center mt-16">
                        <h2 className="text-sm font-thin">
                            MENU
                        </h2>
                        <a className={`${path === '/blog' ? 'font-medium underline' : ''}`} href={'/blog'}>
                            Home
                        </a>
                        <a className={`${path === '/blog/posts' ? 'font-medium underline' : ''}`} href={'/blog/posts'}>
                            All Posts
                        </a>
                        <a href={'/projects'}>
                            Open Law Projects
                        </a>

                        <div className="bg-white h-0.5 w-52 my-2">
                        </div>
                        <a href={'/open-law/contact-us'}>Contact Us</a>

                    </nav>
                </header>
            </div>
        </nav>
    )

}

export default BlogSideMenu
