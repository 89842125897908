import { PortableTextRenderer } from '@open-law/open-law-shared'
import { Link }                 from 'gatsby'
import React, { FunctionComponent } from 'react'
import { BlogPostData }             from '../../../types/vr-blog'

interface VrBlogPostProps {
    data: BlogPostData
}

const OpenLawBlogPost: FunctionComponent<VrBlogPostProps> = (props) => {

    const {data} = props

    return (
        <article className="p-8">
            <header className="mb-6">
                {
                    data.categories ? (
                        <p className="text-heading-green">
                            {
                                data.categories.map((c, idx) => (
                                    <Link to={`/victorian-reports/blog/category/${c.slug.current}`}>
                                        {
                                            c.title
                                        }
                                        {
                                            data.categories.length > 0 && data.categories.length !== idx + 1 ? ', ' : ''
                                        }
                                    </Link>
                                ))
                            }
                        </p>
                    ) : null
                }
                <h1 className="text-3xl font-medium">
                    {data.title}
                    {
                        data.subTitle ? (
                            <>
                                <br/>
                                <span className="text-lg text-gray-700">
                        {data.subTitle}
                    </span>
                            </>
                        ) : null}
                </h1>
                {
                    data?.authors ? data.authors.map((author) => (
                            <p key={author.name} className="text-gray-700 text-lg">
                                {
                                    `${author.name} ${author.title ? `, ${author.title}` : ''}`
                                }
                            </p>
                        )
                    ) : null
                }
                {
                    data.publishedAt ? (
                        <p className="mt-4">
                            Published {data.publishedAt}
                        </p>
                    ) : null
                }
            </header>
            <div className="max-w-xl">
                <PortableTextRenderer projectColour={'blue'} blocks={data.body} className={'vr-blog'}/>
            </div>
        </article>
    )

}

export default OpenLawBlogPost
