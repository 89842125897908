import { createComponentTheme }     from '@open-law/open-law-shared'
import React, { FunctionComponent } from 'react'
import { BlogPageType }             from '../../../types/vr-blog'
import BlogFooter                   from './blog-footer'
import BlogMobileMenu               from './blog-mobile-menu'
import BlogSideMenu                 from './blog-side-menu'

interface BlogLayoutProps {
    path: string
    pageType: BlogPageType
    theme: {theme: string}
}

const BlogLayout: FunctionComponent<BlogLayoutProps> = (props) => {

    const {children, path, theme} = props

    const {
              headingColour,
              backgroundColour,
              highlightColour,
              textColour,
              hover,
              primaryButtonColour,
              primaryButtonText,
              outline
          } = createComponentTheme(theme)
    return (
        <>
            <BlogMobileMenu theme={theme} pageType={props.pageType}/>
            <div style={{maxWidth: '1536px'}} className={'lg:grid lg:grid-cols-sidebar-w-content'}>
                <BlogSideMenu theme={theme} path={path}/>
                <main className="justify-self-start">
                    {children}
                </main>
            </div>
            <BlogFooter theme={theme}/>
        </>
    )
}

export default BlogLayout
